import React from "react";
import TextElements from "./TextElements";

const MobileAboutSection = ({ isVisible }: { isVisible: boolean }) => {
  return (
    <div className="flex flex-col items-center justify-center space-y-[80px]">
      <img
        src="/assets/Mobile/Home/AboutPage/founders.svg"
        alt="The founders"
        className="w-[274px]"
      />
      <TextElements isVisible={isVisible} />
    </div>
  );
};

export default MobileAboutSection;

import HeadingTextAnimation from "components/HeadingTextAnimation";
import TitleText from "./TitleText";

const TextElements = ({ isVisible }: { isVisible: boolean }) => {
  return (
    <div className="space-y-[50px]">
      <TitleText />
      <div className="font-smoother font-robotol text-[14px] text-white leading-[114.5%] space-y-[17px]">
        <div className="">
          <HeadingTextAnimation
            smallText
            text={"We’re not your typical marketing team -"}
            isVisible={isVisible}
            idx={"1"}
          />
          <HeadingTextAnimation
            smallText
            text={"we’re three best friends who turned their"}
            isVisible={isVisible}
            idx={"1"}
          />
          <HeadingTextAnimation
            smallText
            text={"passion for creativity into a business."}
            isVisible={isVisible}
            idx={"1"}
          />
          <HeadingTextAnimation
            smallText
            text={"What started as late-night brainstorming"}
            isVisible={isVisible}
            idx={"1"}
          />
          <HeadingTextAnimation
            smallText
            text={"sessions and bold ideas over coffee"}
            isVisible={isVisible}
            idx={"1"}
          />
          <HeadingTextAnimation
            smallText
            text={"evolved into a dynamic agency that’s all"}
            isVisible={isVisible}
            idx={"1"}
          />
          <HeadingTextAnimation
            smallText
            text={"about helping brands thrive in today’s"}
            isVisible={isVisible}
            idx={"1"}
          />
          <HeadingTextAnimation
            smallText
            text={"fast-paced digital world."}
            isVisible={isVisible}
            idx={"1"}
          />
        </div>
        <p className="text-squeeze-yellow font-robotoB leading-[104%] text-[19px]">
          What sets us apart?
        </p>
        <div>
          <HeadingTextAnimation
            smallText
            text={"It’s all about the chemistry. With years of"}
            isVisible={isVisible}
            idx={"2"}
            extraClass="-tracking-[0.01em]"
          />
          <HeadingTextAnimation
            smallText
            text={"friendship behind us, we bring trust, laughter,"}
            isVisible={isVisible}
            idx={"2"}
            extraClass="-tracking-[0.03em]"
          />
          <HeadingTextAnimation
            smallText
            text={"and a shared passion for innovation into"}
            isVisible={isVisible}
            idx={"2"}
            extraClass="-tracking-[0.02em]"
          />
          <HeadingTextAnimation
            smallText
            text={"every project. Our job doesn’t feel like a job"}
            isVisible={isVisible}
            idx={"2"}
            extraClass="-tracking-[0.01em]"
          />
          <HeadingTextAnimation
            smallText
            text={"– it’s a collaboration, and we love what we"}
            isVisible={isVisible}
            idx={"2"}
            extraClass="-tracking-[0.01em]"
          />
          <HeadingTextAnimation
            smallText
            text={"do. Our love for marketing translates into"}
            isVisible={isVisible}
            idx={"2"}
          />
          <HeadingTextAnimation
            smallText
            text={"our work, helping us create impactful and"}
            isVisible={isVisible}
            idx={"2"}
          />
          <HeadingTextAnimation
            smallText
            text={"result-driven campaigns."}
            isVisible={isVisible}
            idx={"2"}
          />
        </div>
      </div>
    </div>
  );
};

export default TextElements;

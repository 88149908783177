import React from "react";

const Arrows = () => {
	return (
		<>
			<img
				src='/assets/Mobile/Home/GetThereSection/small-arrow.svg'
				alt='underline'
				className={`absolute top-[235px] left-[225px]`}
			/>
			<img
				src='/assets/Mobile/Home/GetThereSection/big-arrow.svg'
				alt='big arrow'
				className={`absolute top-[735px] -left-[20px] z-[100]`}
			/>
		</>
	);
};

export default Arrows;

import { toTop } from "lib/utils";
import React from "react";
import { Link } from "react-router-dom";

const MobileLinks = ({
  setIsOpen,
}: {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const handleLinkClick = () => {
    setIsOpen(false);
    toTop();
  };

  return (
    <div className="w-full lowercase text-[20px] mobile-l:text-[25px] tablet:text-[30px] text-white">
      <Link
        to="/"
        className={`flex w-full justify-between border-b-[0.5px] border-b-white h-[5vh]  min-h-fit items-center`}
        onClick={(e) => {
          if (window.location.pathname === "/") {
            e.preventDefault();
            window.location.reload();
            toTop()
          } else {
            handleLinkClick();
          }
        }}
      >
        <p className="font-roboto tracking-[5%]">home</p>
        <img
          src="/assets/Mobile/Header/arrow.svg"
          alt="Yellow arrow"
          className="w-[10%]"
        />
      </Link>
      <Link
        to="/services"
        className={`flex w-full justify-between border-b-[0.5px] border-b-white h-[5vh] min-h-fit items-center`}
        onClick={(e) => {
          if (window.location.pathname === "/services") {
            e.preventDefault();
            window.location.reload();
            toTop()
          } else {
            handleLinkClick();
          }
        }}
      >
        <p className="font-roboto tracking-[5%]">services</p>
        <img
          src="/assets/Mobile/Header/arrow.svg"
          alt="Yellow arrow"
          className="w-[10%]"
        />
      </Link>
      <Link
        to="/about"
        className={`flex w-full justify-between border-b-[0.5px] border-b-white h-[5vh] min-h-fit items-center`}
        onClick={(e) => {
          if (window.location.pathname === "/about") {
            e.preventDefault();
            window.location.reload();
          } else {
            handleLinkClick();
          }
        }}
      >
        <p className="font-roboto tracking-[5%]">About us</p>
        <img
          src="/assets/Mobile/Header/arrow.svg"
          alt="Yellow arrow"
          className="w-[10%]"
        />
      </Link>
      <Link
        to="/blog"
        className={`flex w-full justify-between border-b-[0.5px] border-b-white h-[5vh] min-h-fit items-center`}
        onClick={() => {
          handleLinkClick();
        }}
      >
        <p className="font-roboto tracking-[5%]">blog</p>
        <img
          src="/assets/Mobile/Header/arrow.svg"
          alt="Yellow arrow"
          className="w-[10%]"
        />
      </Link>
      <Link
        to="/services/faq"
        className={`flex w-full justify-between border-b-[0.5px] border-b-white h-[5vh] min-h-fit items-center`}
        onClick={(e) => {
          if (window.location.pathname === "/services/faq") {
            e.preventDefault();
            window.location.reload();
            toTop()
          } else {
            handleLinkClick();
          }
        }}
      >
        <p className="font-roboto tracking-[5%]">FAQ</p>
        <img
          src="/assets/Mobile/Header/arrow.svg"
          alt="Yellow arrow"
          className="w-[10%]"
        />
      </Link>
    </div>
  );
};

export default MobileLinks;

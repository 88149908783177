import { toastDefaultOptions } from "lib/consts";
import { iLeadMagnet } from "lib/interfaces";
import React, { useState } from "react";
import { isSafari } from "react-device-detect";
import { toast } from "react-toastify";
import { blogService } from "services/blog";

interface LeadMagnetProps {
  leadMagnet: iLeadMagnet;
}

const LeadMagnet: React.FC<LeadMagnetProps> = ({ leadMagnet }) => {
  const [email, setEmail] = useState<string>("");
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handleSubmit = () => {
    if (email === "") {
      toast.error("Please enter an e-mail.", toastDefaultOptions);
    } else {
      blogService
        .subscribeToNewsletter(email, leadMagnet.id)
        .then((response) => {
          if (response?.success) {
            toast.success(
              "You have successfuly subscribed to our newsletter. Check your E-mail for confirmation.",
              toastDefaultOptions
            );
          } else toast.error(response?.message, toastDefaultOptions);
        })
        .catch((Error) => {
          toast.error(Error, toastDefaultOptions);
        });
    }
  };
  return (
    <div
      className={`laptop:sticky ${
        isSafari ? "laptop:top-[15vh]" : "laptop:top-[11vh]"
      } w-[80vw] mobile-l:w-[70vw] max-laptop:max-w-[400px] tablet:w-[50vw] laptop:w-[24.7%] max-laptop:my-6 p-5 space-y-[5%] h-fit bg-${
        leadMagnet.bgColor
      } text-${leadMagnet.textColor} rounded-[10px]`}
    >
      <p className="text-[20px] laptop:text-[22px] laptop:w-[80%] font-poppinsEb leading-[31px]">
        {leadMagnet.title}
      </p>

      <div className="flex items-center gap-3 w-full leading-[117.5%] h-full ">
        <input
          type="text"
          value={email}
          placeholder="Email address"
          className={`font-roboto focus:outline-none text-[15px] laptop:text-[13px] px-3 py-2 w-[59%] h-[17%] text-${leadMagnet.bgColor} px-2 rounded-[62px] bg-${leadMagnet.textColor} `}
          onChange={handleChange}
        />
        <button
          className={`w-[69px] aspect-[69/30] cursor-pointer bg-${leadMagnet.bgColor} text-${leadMagnet.textColor} rounded-[62px] border-${leadMagnet.textColor} flex items-center justify-center font-poppinsSb text-[11px] border-[1.5px] `}
          onClick={() => {
            handleSubmit();
          }}
        >
          SUBMIT
        </button>
      </div>
      <p className="font-roboto text-[11px] leading-[117.5%]">
        We will not share your information with anyone.
      </p>
    </div>
  );
};

export default LeadMagnet;

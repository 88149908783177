import HeadingTextAnimation from "components/HeadingTextAnimation";

const DescText = ({ isVisible }: { isVisible: boolean }) => {
  return (
    <div className="font-smoother font-robotol text-[14px] text-white leading-[114.5%] space-y-[17px]">
      <div className="">
        <HeadingTextAnimation
          smallText
          text={"Right from the start, we knew what our"}
          isVisible={isVisible}
          idx={"1"}
        />
        <HeadingTextAnimation
          smallText
          text={"vision was: Inspire and encourage creative"}
          isVisible={isVisible}
          idx={"1"}
        />
        <HeadingTextAnimation
          smallText
          text={"minds to break the status quo, fostering"}
          isVisible={isVisible}
          idx={"1"}
        />
        <HeadingTextAnimation
          smallText
          text={"a world where boundless imagination"}
          isVisible={isVisible}
          idx={"1"}
        />
        <HeadingTextAnimation
          smallText
          text={"and transformative ideas thrive."}
          isVisible={isVisible}
          idx={"1"}
        />
      </div>
      <div>
	  <HeadingTextAnimation
  smallText
  text={"It was clear as day we had our mission set"}
  isVisible={isVisible}
  idx={"2"}
/>
<HeadingTextAnimation
  smallText
  text={"out for ourselves: Empower businesses"}
  isVisible={isVisible}
  idx={"2"}
/>
<HeadingTextAnimation
  smallText
  text={"with data-driven marketing strategies and"}
  isVisible={isVisible}
  idx={"2"}
/>
<HeadingTextAnimation
  smallText
  text={"a user-centric approach tailored to their"}
  isVisible={isVisible}
  idx={"2"}
/>
<HeadingTextAnimation
  smallText
  text={"specific needs."}
  isVisible={isVisible}
  idx={"2"}
/>

      </div>
    </div>
  );
};

export default DescText;

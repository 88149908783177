import ChatButton from "components/ChatButton";
import { toTop } from "lib/utils";
import { Link } from "react-router-dom";

const Header = () => {
  return (
    <div className=" z-40 flex items-center w-full justify-center mx-[80px] h-[65px]">
      <div className="relative flex items-center justify-between w-full">
        <Link to="/">
          <img
            src="/assets/logo.svg"
            alt="Squeeze creative agency logo "
            className="scale-75"
          />
        </Link>
        <div
          className={`flex w-fit gap-[2.12vw] justify-between text-squeeze-darkBlue text-[14px] font-roboto `}
        >
          <div
            className={` flex items-center justify-center hover:text-squeeze-pink`}
          >
            <Link
              onClick={(e) => {
                if (window.location.pathname === "/") {
                  e.preventDefault();
                  window.location.reload();
                } else {
                  toTop();
                }
              }}
              to="/"
            >
              home
            </Link>
          </div>
          <div
            className={` flex items-center justify-center hover:text-squeeze-pink`}
          >
            <Link
              onClick={(e) => {
                if (window.location.pathname === "/services") {
                  e.preventDefault();
                  window.location.reload();
                } else {
                  toTop();
                }
              }}
              to="/services"
            >
              services
            </Link>
          </div>
          <div
            className={` flex items-center justify-center hover:text-squeeze-pink`}
          >
            <Link
              to="/about"
              onClick={(e) => {
                if (window.location.pathname === "/about") {
                  e.preventDefault();
                  window.location.reload();
                }
              }}
            >
              about us
            </Link>
          </div>
          <div
            className={` flex items-center justify-center hover:text-squeeze-pink`}
          >
            <Link
              onClick={() => {
                toTop();
              }}
              to="/blog"
            >
              blog
            </Link>
          </div>
          <div
            className={` flex items-center justify-center hover:text-squeeze-pink`}
          >
            <Link
              to="/services/faq"
              onClick={(e) => {
                if (window.location.pathname === "/services/faq") {
                  e.preventDefault();
                  window.location.reload();
                }
              }}
            >
              FAQ
            </Link>
          </div>
          <div className={` flex items-center justify-center`}>
            <ChatButton />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;

import HeadingTextAnimation from "components/HeadingTextAnimation";
import React from "react";

const HeadingText = ({ isVisible }: { isVisible: boolean }) => {
  return (
    <>
      <img
        src="/assets/ClosingSection/text-rect.svg"
        alt="Border around text"
        className={` absolute top-[0px] left-[0px]`}
      />
      <div className="font-poppinsB text-[33px] absolute top-[40px] left-[53px] -space-y-[35px] text-white ">
        <div
          className="flex space-x-[9px] items-center justify-start w-fit h-fit "
          style={{ transform: "translateY(12px)" }}
        >
          <div className=" h-fit">
            <HeadingTextAnimation
              text={"With"}
              isVisible={isVisible}
              idx={"1"}
            />
          </div>
          <div className="uppercase text-squeeze-yellow font-poppinsBl text-[40px] ">
            <HeadingTextAnimation
              text={"10+ years"}
              isVisible={isVisible}
              idx={"1"}
              extraClass="mb-[18px] "
            />
          </div>
        </div>
        <div className=" h-fit">
          <HeadingTextAnimation
            text={"of experience in the"}
            isVisible={isVisible}
            idx={"1"}
          />
        </div>
        <div className=" h-fit">
          <HeadingTextAnimation
            text={"industry, we came"}
            isVisible={isVisible}
            idx={"1"}
          />
        </div>
        <div className=" h-fit">
          <HeadingTextAnimation
            text={"together to build"}
            isVisible={isVisible}
            idx={"1"}
          />
        </div>
        <div className="uppercase text-squeeze-yellow font-poppinsBl text-[40px] h-fit  ">
          <HeadingTextAnimation
            text={"Squeeze Creative."}
            isVisible={isVisible}
            idx={"3"}
            extraClass=" "
          />
        </div>
      </div>
    </>
  );
};

export default HeadingText;

import TitleText from "./TitleText";
import HeadingTextAnimation from "components/HeadingTextAnimation";

const TextElements = ({ isVisible }: { isVisible: boolean }) => {
  return (
    <div className="w-[550px] flex flex-col items-end">
      <div className="space-y-[70px]">
        <TitleText isVisible={isVisible} />
        <div className="space-y-[20px] font-robotol leading-[114.5%] text-white text-[20px]">
          <div className="">
            <HeadingTextAnimation
              smallText
              text={"We’re not your typical marketing team - we’re"}
              isVisible={isVisible}
              idx={"1"}
            />
            <HeadingTextAnimation
              smallText
              text={"three best friends who turned their passion for"}
              isVisible={isVisible}
              idx={"1"}
            />
            <HeadingTextAnimation
              smallText
              text={"creativity into a business. What started as late-"}
              isVisible={isVisible}
              idx={"1"}
            />
            <HeadingTextAnimation
              smallText
              text={"night brainstorming sessions and bold ideas over"}
              isVisible={isVisible}
              idx={"1"}
            />
            <HeadingTextAnimation
              smallText
              text={"coffee evolved into a dynamic agency that’s all"}
              isVisible={isVisible}
              idx={"1"}
            />
            <HeadingTextAnimation
              smallText
              text={"about helping brands thrive in today’s fast-paced"}
              isVisible={isVisible}
              idx={"1"}
            />
            <HeadingTextAnimation
              smallText
              text={"digital world."}
              isVisible={isVisible}
              idx={"1"}
            />
          </div>
          <HeadingTextAnimation
          smallText
            isVisible={isVisible}
            extraClass="text-squeeze-yellow font-robotoB leading-[104%] text-[30px]"
            text={` What sets us apart?`}
            idx={`3`}
          />
          <div>
            <HeadingTextAnimation
              smallText
              text={"It’s all about the chemistry. With years of"}
              isVisible={isVisible}
              idx={"4"}
            />
            <HeadingTextAnimation
              smallText
              text={"friendship behind us, we bring trust, laughter,"}
              isVisible={isVisible}
              idx={"4"}
            />
            <HeadingTextAnimation
              smallText
              text={"and a shared passion for innovation into every"}
              isVisible={isVisible}
              idx={"4"}
            />
            <HeadingTextAnimation
              smallText
              text={"project. Our job doesn’t feel like a job – it’s a"}
              isVisible={isVisible}
              idx={"4"}
            />
            <HeadingTextAnimation
              smallText
              text={"collaboration, and we love what we do. Our love"}
              isVisible={isVisible}
              idx={"4"}
            />
            <HeadingTextAnimation
              smallText
              text={"for marketing translates into our work, helping"}
              isVisible={isVisible}
              idx={"4"}
            />
            <HeadingTextAnimation
              smallText
              text={"us create impactful and result-driven campaigns."}
              isVisible={isVisible}
              idx={"4"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TextElements;

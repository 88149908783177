import { Category } from 'lib/interfaces';

const DesktopCategories = ({
    categories,
    selectedCategory,
    setSelectedCategory,
  }: {
    categories: Category[];
    selectedCategory: string;
    setSelectedCategory: (category: string) => void;
  }) => {
  return (
    <div className="max-laptop:hidden flex flex-wrap gap-[1.5%] max-tablet:px-[5vw] w-[78vw] justify-self-center max-w-[1183px] h-fit justify-center items-center font-roboto font-semibold">
    {categories.map((cat, idx) => (
      <button
        key={cat.id}
        onClick={() => {
          if (selectedCategory === cat.title) {
            setSelectedCategory("");
          } else {
            setSelectedCategory(cat.title);
          }
        }}
        className={`${selectedCategory===cat.title?'text-white bg-squeeze-pink border-transparent':'bg-transparent border-[#BABABA] text-squeeze-darkBlue'} max-tablet:mt-2 relative tracking-[0.11em] laptop:w-fit laptop:h-[31px] px-4 rounded-[101px] text-[8px] mobile-l:text-[10px] laptop:text-[12px] border-[0.73px] uppercase hover:bg-squeeze-pink hover:text-white hover:border-transparent`}
      >
        <p className=" whitespace-nowrap">
          {cat.title}
        </p>
      </button>
    ))}
  </div>
  )
}

export default DesktopCategories
import HeadingTextAnimation from "components/HeadingTextAnimation";

const TextElements = ({ isVisible }: { isVisible: boolean }) => {
	return (
		<div className='relative flex flex-col items-center justify-center h-fit w-[450px]'>
			<div className='-space-y-[7px] relative h-fit w-full text-squeeze-darkBlue font-poppinsB text-[43px] leading-[97%] flex flex-col items-center justify-center '>
				<HeadingTextAnimation idx={"1"} isVisible={isVisible} text='where' 
				/>

				<HeadingTextAnimation
					idx={"2"}
					isVisible={isVisible}
					text='imagination'
				/>

				<HeadingTextAnimation
					idx={"3"}
					isVisible={isVisible}
					text='meets results'
					extraClass='text-squeeze-pink '
				/>
				<img
					src='/assets/Mobile/Services/ImagineSection/rect.svg'
					alt='Yellow rect'
					className='absolute scale-105 top-[50px] left-[90px] -rotate-[2.47deg]'
				/>
			</div>

			<div className='flex flex-col leading-[129%] tracking-[0%] font-robotol text-[16px] text-center space-y-[8px] mt-[20px]'>
				<p className={``}>We believe in your business’s potential.</p>
				<p className={``}>
					Discover the range of creative solutions
					<br />
					we offer to elevate your brand and drive
					<br />
					unparalleled results.
				</p>
			</div>
			<button
				onClick={() => {
					const scroller = document.getElementById("scrollDemo");
					if (scroller) {
						scroller.scrollTo({
							top: scroller.scrollHeight,
							behavior: "smooth",
						});
					}
				}}
				className={`mt-[20px] uppercase  flex items-center justify-center rounded-[47.55px] bg-squeeze-white border-[0.9px]  border-squeeze-pink w-[118px]  h-[38px] text-[16px] font-poppinsSb text-squeeze-pink `}
			>
				let's go
			</button>
		</div>
	);
};

export default TextElements;

import { useGSAP } from "@gsap/react";
import Footer from "components/Footer/Footer";
import Header from "components/Header/Header";
import HeadingTextAnimation from "components/HeadingTextAnimation";
import AboutSection from "components/Home/AboutSection/AboutSection";
import ClosingParallaxElements from "components/Home/ClosingSection/ClosingParallaxElements";
import ClosingSection from "components/Home/ClosingSection/ClosingSection";
import EyeSection from "components/Home/EyeSection/EyeSection";
import GetThere from "components/Home/GetThereSection/GetThere";
import Cards from "components/Home/PinkSections/Cards";
import GoodBad from "components/Home/PinkSections/GoodBadSection/GoodBad";
import ParallaxElements from "components/Home/PinkSections/ParallaxElements";
import ServicesSection from "components/Home/ServicesSection/ServicesSection";
import UnleashSection from "components/Home/UnleashSection/UnleashSection";
import YouSection from "components/Home/YouSection/YouSection";
import { usePopup } from "contexts/PopupContext";
import { gsap } from "gsap";
import ScrollTrigger from "gsap/dist/ScrollTrigger";
import { toTop } from "lib/utils";
import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
gsap.registerPlugin(ScrollTrigger);

const Home: React.FC = () => {
  useGSAP(() => {
    const sections = document.querySelectorAll<HTMLElement>("[data-bgcolor]");
    if (visibleSection === null) {
      setVisibleSection("unleash");
      setPrevVisibleSection("unleash");
    }
    sections.forEach((section, i) => {
      const prevBgColor =
        i === 0 ? "#ffffff" : sections[i - 1].dataset.bgcolor || "";

      gsap.timeline({
        scrollTrigger: {
          trigger: section,
          scroller: "#scrollDemo",
          start: "top 50%",
          end: "bottom 50%",
          onEnter: () => {
            if (prevVisibleSection !== section.id) {
              setVisibleSection(section.id || null);
              setPrevVisibleSection(section.id);
            }
            gsap.to("#scrollDemo", {
              backgroundColor: section.dataset.bgcolor,
              color: section.dataset.textcolor,
              overwrite: "auto",
            });
            gsap.to(section, {
              opacity: 1,
              visibility: "visible",
              duration: 1.5,
            });
          },
          onLeave: () => {
            if (section.id === "aboutSection") {
            } else {
              gsap.to(section, {
                opacity: 0,
                visibility: "hidden",
                duration: 1.5,
              });
            }
          },
          onEnterBack: () => {
            if (prevVisibleSection !== section.id) {
              setVisibleSection(section.id || null);
              setPrevVisibleSection(section.id);
            }
            gsap.to("#scrollDemo", {
              backgroundColor: section.dataset.bgcolor,
              color: section.dataset.textcolor,
              overwrite: "auto",
            });
            gsap.to(section, {
              opacity: 1,
              visibility: "visible",
              duration: 1.5,
            });
          },
          onLeaveBack: () => {
            gsap.to("#scrollDemo", {
              backgroundColor: prevBgColor,
              overwrite: "auto",
            });
            gsap.to(section, {
              opacity: 0,
              visibility: "hidden",
              duration: 1.5,
            });
          },
        },
      });
    });
  }, []);

  const scrollDemoRef = useRef<HTMLDivElement | null>(null);

  const [lastScroll, setLastScroll] = useState<number>(0);

  const [prevVisibleSection, setPrevVisibleSection] = useState<string | null>(
    null
  );
  const [visibleSection, setVisibleSection] = useState<string | null>(null);

  const [trustFlag, setTrustFlag] = useState<boolean>(false);

  useGSAP(() => {
    gsap.timeline({
      scrollTrigger: {
        trigger: "#trustSection",
        scroller: "#scrollDemo",
        start: "top center",
        end: "130% center",
        onEnter: () => {
          setTrustFlag(true);
        },
        onEnterBack: () => {
          setTrustFlag(true);
        },
        onLeave: () => {
          setTrustFlag(false);
        },
        onLeaveBack: () => {
          setTrustFlag(false);
        },
      },
    });
  }, []);

  useGSAP(() => {
    const timelineCsRectTR = gsap.timeline({
      scrollTrigger: {
        trigger: "#closingCont",
        scroller: "#scrollDemo",
        start: "top center",
        end: "center center",

        scrub: 2,
      },
    });
    timelineCsRectTR.fromTo(
      "#csRectTR",
      { x: 1130, y: 200, scale: 1.5 },
      { x: 1130, y: 270, scale: 1 }
    );
    const timelineCsRectMr = gsap.timeline({
      scrollTrigger: {
        trigger: "#closingCont",
        scroller: "#scrollDemo",
        start: "top center",
        end: "center center",

        scrub: 3,
      },
    });
    timelineCsRectMr.fromTo(
      "#csRectMR",
      { x: 995, y: 650, scale: 0.5 },
      { x: 995, y: 550, scale: 1 }
    );
    const timelineCsRectBr = gsap.timeline({
      scrollTrigger: {
        trigger: "#closingCont",
        scroller: "#scrollDemo",
        start: "top center",
        end: "center center",

        scrub: 3,
      },
    });
    timelineCsRectBr.fromTo(
      "#csRectBR",
      { x: 1330, y: 400, rotate: 20 },
      { x: 1330, y: 280, rotate: 0 }
    );
    const timelineCsRectTL = gsap.timeline({
      scrollTrigger: {
        trigger: "#closingCont",
        scroller: "#scrollDemo",
        start: "top center",
        end: "center center",

        scrub: 6,
      },
    });
    timelineCsRectTL.fromTo(
      "#csRectL",
      { x: -150, y: 600, rotate: 20 },
      { x: -150, y: 420, rotate: 0 }
    );
  }, []);

  const handleScroll = () => {
    const head = document.getElementById("header");
    const headLines = document.getElementById("headLines");
    const headLinks = document.getElementById("headLinks");

    if (scrollDemoRef.current) {
      const { scrollTop } = scrollDemoRef.current;

      headLinks?.classList.add("hidden");
      headLinks?.classList.remove("flex");
      headLines?.classList.remove("hidden");

      if (scrollTop <= 0) {
        head?.classList.remove("scroll-down");
        head?.classList.add("scroll-up");
      } else if (scrollTop > lastScroll) {
        head?.classList.remove("scroll-up");
        head?.classList.add("scroll-down");
      } else {
        head?.classList.remove("scroll-down");
        head?.classList.add("scroll-up");
      }

      setLastScroll(scrollTop);
    }
  };

  const { setBgName } = usePopup();

  useEffect(() => {
    setBgName(visibleSection || "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visibleSection]);

  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/about") {
      const aboutSection = document.getElementById(
        "aboutSection"
      ) as HTMLElement | null;
      const scrollContainer = scrollDemoRef.current as HTMLElement | null;

      if (aboutSection && scrollContainer) {
        // Calculate the position of the about section relative to the scroller
        const targetOffset = aboutSection.offsetTop - scrollContainer.offsetTop;

        gsap.to(scrollContainer, {
          scrollTo: { y: targetOffset, autoKill: false },
          duration: 1,
        });
      }
    } else {
      toTop()
      // const unleash = document.getElementById("unleash");
      // if (unleash && scrollDemoRef.current) {
      //   gsap.to(scrollDemoRef.current, {
      //     scrollTo: { y: unleash.offsetTop - 100, autoKill: false },
      //     duration: 1,
      //   });
      // }
    }
  }, [location.pathname]);

  useEffect(() => {
    ScrollTrigger.refresh();
    if (scrollDemoRef.current) {
      scrollDemoRef.current.scrollTop = 0; // Reset scroll to the top
    }
  }, []);
  return (
    <div
      id="scrollDemo"
      ref={scrollDemoRef}
      onScroll={handleScroll}
      className="relative flex flex-col justify-start w-full h-screen animation overflow-x-clip max-laptop:hidden main scroller"
    >
      <div
        id="header"
        className="laptop:home-header max-laptop:hidden fixed top-0 z-[300] flex items-center w-full bg-white h-fit py-[10px]"
      >
        <Header />
      </div>
      <section id="unleash" className={` section`} data-bgcolor="#ffffff">
        <div className="relative flex flex-col items-center justify-center overflow-x-visible widthClass">
          <div className="w-[1512px] flex flex-col items-center justify-center">
            <UnleashSection isVisible={visibleSection === "unleash"} />
          </div>
        </div>
      </section>
      <section
        id="cardsTrustGoodBad"
        className={`h-[2499px] container relative flex flex-col items-center w-widthClass mx-auto opacity-0 `}
        data-bgcolor="#FF0175"
      >
        <ParallaxElements />
        <div className="h-[1666px] relative flex flex-col items-center justify-center widthClass overflow-x-clip">
          <Cards />
          <div
            className="w-[1512px] h-[833px] flex flex-col items-center justify-center text-white whitespace-nowrap"
            id="trustSection"
          >
            <HeadingTextAnimation
              idx={"1"}
              isVisible={trustFlag}
              text="Trust us"
              extraClass="text-[96px] font-poppinsBl text-white"
            />
            <HeadingTextAnimation
              idx={"3"}
              isVisible={trustFlag}
              text="We understand your problem"
              extraClass="text-[48px] font-poppins text-white"
            />
          </div>
        </div>
        <GoodBad />
      </section>
      <section
        id="getThere"
        className={`h-[1080px] section`}
        data-bgcolor="#FFD402"
      >
        <GetThere isVisible={visibleSection === "getThere"} />
      </section>
      <section
        id="youSection"
        className={`h-[1065px] section`}
        data-bgcolor="#0C1B31"
      >
        <YouSection isVisible={visibleSection === "youSection"} />
      </section>
      <section
        id="servicesSection"
        className={`h-[1490px] section`}
        data-bgcolor="#FFD402"
      >
        <ServicesSection />
      </section>
      <section
        id="eyeSection"
        className={`
					h-[1172px] section`}
        data-bgcolor="#0C1B31"
      >
        <EyeSection isVisible={visibleSection === "eyeSection"} />
      </section>
      <section
        id="closingSection"
        className={`h-[1200px] section`}
        data-bgcolor="#FF0175"
      >
        <div className="h-[1000px] relative flex flex-col items-center justify-center widthClass overflow-x-visible">
          <div className="w-[1512px] h-[1400px] flex flex-col items-center justify-center">
            <div className="widthClass h-[1400px] realtive" id="closingCont">
              <ClosingParallaxElements />
              <ClosingSection isVisible={visibleSection === "closingSection"} />
            </div>
          </div>
        </div>
      </section>
      <section
        id="aboutSection"
        className={`h-[800px] section`}
        data-bgcolor="#FF0175"
      >
        <div className="h-[800px]relative flex flex-col items-center justify-center widthClass overflow-x-visible">
          <div className="w-[1512px] h-[800px] flex flex-col items-center justify-center">
            <div className="widthClass h-[800px] realtive">
              <AboutSection isVisible={visibleSection === "aboutSection"} />
            </div>
          </div>
        </div>
      </section>
      <Footer />
      <ToastContainer />
    </div>
  );
};

export default Home;

import { ShortPost } from "lib/interfaces";
import { toTop } from "lib/utils";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const Post = ({ post }: { post: ShortPost }) => {
  const apiUrl = `${process.env.REACT_APP_API_URL}`;
  const [isTablet, setIsTablet] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsTablet(window.innerWidth >= 768); // Adjust the breakpoint if needed
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const Wrapper = isTablet ? "div" : Link;
  const CounterWrapper = isTablet ? Link : "div";
  return (
    <Wrapper
      to={`/blog/${post.title}/${post.id}`}
      onClick={() => {
        if (isTablet) {
        } else {
          toTop();
        }
      }}
      className="w-full h-full grid-rows-7 tablet:grid tablet:-mt-[2%] -mt-[10px]"
    >
      <div
        className={`rounded-[27px] w-full bg-cover overflow-hidden self-center tablet:aspect-[16/10] aspect-[287.88/140] row-span-4 ${
          post.thumbnail === "" || !post.thumbnail ? "bg-squeeze-yellow" : ""
        }`}
      >
        <CounterWrapper
          to={`/blog/${post.title}/${post.id}`}
          onClick={() => {
            toTop();
          }}
          className="object-cover w-full h-full"
        >
          {post.thumbnail && (
            <img
              src={`${apiUrl}${post.thumbnail}`}
              alt={`Not found`}
              className="object-cover w-full h-full scale-150"
            />
          )}
        </CounterWrapper>
      </div>

      <div className="flex flex-col self-end justify-between w-full h-full row-span-3">
        <div className="space-y-[2%] self-start pb-[4%] tabelt:pt-[2%] pt-[4%]">
          <p
            className={`break:text-[14px] text-[9px] mobile-l:text-[13px] font-robotoF font-semibold leading-[129%] tracking-[0.11rem] text-squeeze-pink uppercase`}
          >
            {post.category.title}
          </p>
          <p
            className={`font-poppinsSb laptop:text-[22px] mobile-m:text-[20px] mobile-l:text-[28px] leading-[119%] -tracking-[0.015rem] text-squeeze-darkBlue`}
          >
            {post.title}
          </p>
          <p
            className={`font-robotol laptop:text-[15px] mobile-l:text-[20px] text-[13px] tablet:leading-[18px] leading-[130%] text-squeeze-darkBlue line-clamp-3`}
          >
            {post.description}
          </p>
        </div>
        <CounterWrapper
          to={`/blog/${post.title}/${post.id}`}
          onClick={() => {
            toTop();
          }}
          className={`max-laptop:hidden hover:bg-squeeze-pink justify-self-end hover:text-white laptop:px-[19px] tablet:px-[15px] tablet:py-[10px] px-[10px] py-[5px] laptop:py-[8px] h-fit w-fit uppercase border-squeeze-pink border-[0.73px] rounded-[38.67px] laptop:text-[12px] text-sm leading-[117.5%] font-poppinsSb text-squeeze-pink`}
        >
          Read more
        </CounterWrapper>
      </div>
    </Wrapper>
  );
};

export default Post;

import { Link } from "react-router-dom";

const ServiceLinks = () => {
	return (
		<>
			<Link
				to='/services'
				className='absolute top-[330px] left-[414px] cursor-pointer'
			>
				<img
					src='/assets/ServicesSection/1.svg'
					alt='Graphic design'
					className='transition-transform duration-300 hover:scale-105 -rotate-[3.91deg]'
				/>
			</Link>
			<Link
				to='/services'
				className='absolute top-[714px] left-[790px] z-0 hover:z-30 cursor-pointer'
			>
				<img
					src='/assets/ServicesSection/2.svg'
					alt='Social Media Marketing'
					className='transition-transform duration-300 hover:scale-105 rotate-[2.24deg]'
				/>
			</Link>
			<Link
				to='/services'
				className='absolute top-[675px] left-[270px] z-20 hover:z-30 cursor-pointer'
			>
				<img
					src='/assets/ServicesSection/3.svg'
					alt='Copywriting and dontent writing'
					className='transition-transform duration-300 hover:scale-105'
				/>
			</Link>
			<Link
				to='/services'
				className='absolute top-[424px] left-[786px] z-0 hover:z-30 cursor-pointer'
			>
				<img
					src='/assets/ServicesSection/4.svg'
					alt='SEO'
					className='transition-transform duration-300 hover:scale-105 -rotate-[1.68deg]'
				/>
			</Link>
		</>
	);
};

export default ServiceLinks;

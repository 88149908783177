import { usePopup } from "contexts/PopupContext";
import React from "react";
import ContactForm from "./ContactForm";
import ContactSection from "./ContactSection";

const ContactPopup = ({ section }: { section?: string }) => {
	const { popupOpen, setPopupOpen, bgTrigger } = usePopup();

	const handleClick = () => {
		setPopupOpen(false);
	};
	const handleInnerClick = (e: React.MouseEvent<HTMLDivElement>) => {
		e.stopPropagation();
	};

	return (
		<div
			onClick={handleClick}
			className={`fixed  z-[310] ${
				popupOpen ? "w-screen right-0" : "w-[0px] -right-[30vw]"
			} h-screen max-laptop:hidden`}
		>
			<div
				className={`bg-black ${
					popupOpen ? "opacity-50" : "opacity-0"
				} transition-[opacity] duration-1000 ease-in-out w-full h-full z-[101]`}
			></div>
			<div
				onClick={handleInnerClick}
				className={`absolute top-0 right-0 h-screen  overflow-x-clip ${
					bgTrigger ? "bg-squeeze-pink" : "bg-squeeze-darkBlue"
				} ${
					popupOpen ? "w-[27vw]" : "w-[0px]"
				} z-[311] transition-[width] duration-700 ease-in-out opacity-100 flex justify-center items-center`}
			>
				<div
					className={`${
						popupOpen ? "opacity-100 w-[27vw]" : "opacity-0 w-[0px]"
					} transition-opacity duration-500 ease-in-out flex flex-col justify-center items-center h-full text-center px-[5vw] space-y-[5vh]`}
				>
					<div
						className={`relative font-poppinsB text-squeeze-yellow laptop:text-[40px] laptop-l:text-[55px] 4k:text-[84px] leading-[96%] -tracking-[3%] whitespace-nowrap`}
					>
						<img
							src='/assets/ContactPopup/stars.svg'
							alt='Stars'
							className='absolute -top-[100px] -right-[30px]'
						/>
						<p>let's do</p>
						<p>great things</p>
						<p>together</p>
					</div>

					<ContactForm bgTrigger={bgTrigger} />
					<ContactSection />
				</div>
			</div>
		</div>
	);
};

export default ContactPopup;

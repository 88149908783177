import { usePopup } from "contexts/PopupContext";
import { useLocation } from "react-router-dom";

const ChatButton = ({
	eclass,
	mobile,
	isOpen,
}: {
	eclass?: string;
	mobile?: boolean;
	isOpen?: boolean;
}) => {
	const { setPopupOpen } = usePopup();

	const location = useLocation();

	const togglePopup = () => {
		if (mobile) {
			if (location.pathname === "/blog") {
				window.scrollTo({
					top: document.documentElement.scrollHeight,
					behavior: "smooth",
				});
			} else {
				const scroller = document.getElementById("scrollDemo");
				if (scroller) {
					scroller.scrollTo({
						top: scroller.scrollHeight,
						behavior: "smooth",
					});
				}
			}
		} else {
			setPopupOpen((prev) => !prev);
		}
	};
	return (
		<div
			className={`hover:bg-squeeze-yellow uppercase whitespace-nowrap flex items-center z-[45] justify-center cursor-pointer ${
				isOpen ? "bg-squeeze-yellow" : "bg-squeeze-pink"
			} rounded-[77px] ${
				mobile
					? " mobile-l:h-[30px] h-[26px] tablet:h-[3vw] px-3 text-[11px] mobile-l:text-[12px]"
					: "w-[114px] h-[34px]"
			}  font-robotoF font-bold hover:text-squeeze-darkBlue ${
				isOpen ? "text-squeeze-darkBlue" : "text-white"
			}`}
			onClick={togglePopup}
		>
			let's chat
		</div>
	);
};

export default ChatButton;

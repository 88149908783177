import { useLayoutEffect, useRef } from "react";

const SingleEye = ({
	id,
	customStyle,
	folder,
	eyeClass,
	glareClass,
}: {
	id: string;
	customStyle?: string;
	folder: string;
	eyeClass: string;
	glareClass: string;
}) => {
	const anchorRef = useRef<HTMLImageElement>(null);
	const eyeRef = useRef<HTMLImageElement>(null);

	useLayoutEffect(() => {
		const handleMouseMove = (e: MouseEvent) => {
			const mouseX = e.clientX;
			const mouseY = e.clientY;

			const anchor = eyeRef.current;
			if (anchor) {
				const rekt = anchor.getBoundingClientRect();
				const anchorX = rekt.left + rekt.width / 2;
				const anchorY = rekt.top + rekt.height / 2;

				const angleDeg = angle(mouseX, mouseY, anchorX, anchorY);

				const eye = eyeRef.current;
				if (eye) {
					eye.style.transform = `rotate(${angleDeg + 270}deg)`;
				}
			}
		};

		document.addEventListener("mousemove", handleMouseMove);
		document.addEventListener("wheel", handleMouseMove);
		return () => {
			document.removeEventListener("mousemove", handleMouseMove);
			document.removeEventListener("wheel", handleMouseMove);
		};
	}, []);

	function angle(cx: number, cy: number, ex: number, ey: number) {
		const dy = ey - cy;
		const dx = ex - cx;
		const rad = Math.atan2(dy, dx);
		const deg = (rad * 180) / Math.PI;
		return deg;
	}

	const outerPath = `/assets/EyeSection/${folder}/outer-eye.svg`;
	const innerPath = `/assets/EyeSection/${folder}/inner-eye.svg`;
	const glarePath = `/assets/EyeSection/${folder}/glare.svg`;
	return (
		<div className={`${customStyle} relative `}>
			<img
				src={outerPath}
				alt='Outer eye'
				id={`anchor-${id}`}
				ref={anchorRef}
				className={`w-full h-full`}
			/>
			<img
				id={`eye-${id}`}
				src={innerPath}
				alt='Inner eye'
				className={`absolute ${eyeClass}`}
				ref={eyeRef}
			/>
			<img src={glarePath} alt='Glare' className={glareClass} />
		</div>
	);
};

export default SingleEye;

import { useEffect, useRef } from "react";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const HeadingTextAnimation = ({
  text,
  isVisible,
  idx,
  extraClass,
  smallText,
  img,
  textClass,
}: {
  text: string;
  isVisible: boolean;
  idx: string;
  extraClass?: string;
  smallText?: boolean,
  img?:boolean,
  textClass?:string
}) => {
  const animationTimeline = useRef<gsap.core.Timeline | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null); // Ref for container

  useEffect(() => {
    // Create a timeline for the animation
    if (animationTimeline.current) {
      animationTimeline.current.kill(); // Kill any existing animation
    }

    animationTimeline.current = gsap.timeline();

    if (isVisible && containerRef.current) {
      const numIdx = parseInt(idx, 10);
      // Trigger animation when isVisible is true
      animationTimeline.current.fromTo(
        containerRef.current.querySelector(
          ".split"
        ) as HTMLElement,
        { yPercent: 130, opacity: 0 },
        {
          yPercent: 0,
          delay: `${numIdx * 0.1}`,
          ease: "back.out(0.7)",
          duration: `${smallText ? 2 : 1}`,
          opacity: 100,
        }
      );
    } else if (containerRef.current) {
      // Reset animation state when not visible
      animationTimeline.current.to(
        containerRef.current.querySelector(
          ".split"
        ) as HTMLElement,
        { yPercent: 130, duration: 0.2 }
      );
    }

    return () => {
      if (animationTimeline.current) {
        animationTimeline.current.kill(); // Clean up animation on component unmount or before new animation starts
      }
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible, idx]);

  return (
    <div
      id={idx}
      className={`container overflow-hidden z-10 w-fit ${extraClass}`}
      ref={containerRef}
    >
      <p className={`${textClass} whitespace-nowrap split ${smallText ? 'h-fit' : img ? 'min-h-[140px]' : textClass?`${textClass}`:'min-h-[77px] max-laptop:min-h-[50px]'}`}>{text}</p>
    </div>
  );
};

export default HeadingTextAnimation;

import { toastDefaultOptions } from "lib/consts";
import { validateEmail } from "lib/utils";
import { useState } from "react";
import { toast } from "react-toastify";
import { contactService } from "services/contact";

const ContactForm = ({ bgTrigger }: { bgTrigger: boolean }) => {
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [isSendingMessage, setIsSendingMessage] = useState<boolean>(false);

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    if (!name || !email || !message) {
      toast.error("Please fill all the information.", toastDefaultOptions);
    } else if (!validateEmail(email)) {
      toast.error("Please enter a valid email address.", toastDefaultOptions);
    } else {
      setIsSendingMessage(true);

      contactService
        .sendMessage(name, email, message)
        .then((response) => {
          if (!response.success) {
            toast.error("An error occured while sending the message", toastDefaultOptions);
          } else {
            toast.success("Message sent!", toastDefaultOptions);
          }
        })
        .catch((error) => {
          toast.error("An error has occured while sending the message.", toastDefaultOptions);

          console.log(error);
        })
        .finally(() => {
          setIsSendingMessage(false);
        });
    }
  };

  return (
    <>
      <form
        onSubmit={handleSubmit}
        className="relative flex flex-col w-[17vw] p h-fit text-white space-y-[21px] text-[20px] "
      >
        <input
          onChange={({ target: { value } }) => setName(value)}
          value={name}
          type="text"
          className="w-full focus:outline-none border-b-[1px] border-white h-[35px] placeholder-white bg-transparent"
          placeholder="Name"
        />
        <input
          onChange={({ target: { value } }) => setEmail(value)}
          value={email}
          type="text"
          className="w-full focus:outline-none border-b-[1px] border-white h-[35px] placeholder-white bg-transparent"
          placeholder="E-mail adress"
        />
        <textarea
          onChange={({ target: { value } }) => setMessage(value)}
          value={message}
          className="w-full resize-none text-justify-start border-b-[1px] outline-none border-white h-[115px] placeholder-white bg-transparent"
          placeholder="Message"
        />
        <button
          type="submit"
          disabled={isSendingMessage}
          className={`self-center uppercase flex items-center justify-center rounded-[63.65px] bg-squeeze-yellow ${
            bgTrigger ? "text-squeeze-pink" : "text-squeeze-darkBlue"
          } w-[129px] h-[45px] text-[17px] font-poppinsSb`}
        >
          Submit
        </button>
      </form>
    </>
  );
};

export default ContactForm;

const ServiceCards = () => {
	return (
		<>
			<div className='absolute top-[700px] z-10 hover:z-30 -rotate-[3deg]'>
				<img
					src='/assets/Mobile/Services/PinkSections/ServiceDetails/ServiceCards/1.svg'
					alt='Graphic design'
				/>
			</div>
			<div className='absolute top-[1025px] z-10 hover:z-30 rotate-[2deg]'>
				<img
					src='/assets/Mobile/Services/PinkSections/ServiceDetails/ServiceCards/2.svg'
					alt='SEO'
				/>
			</div>
			<div className='absolute top-[1280px] z-10 hover:z-30 -rotate-[2deg]'>
				<img
					src='/assets/Mobile/Services/PinkSections/ServiceDetails/ServiceCards/3.svg'
					alt='Copywriting and dontent writing'
				/>
			</div>
			<div className='absolute top-[1620px] z-10 hover:z-30 rotate-[3deg]'>
				<img
					src='/assets/Mobile/Services/PinkSections/ServiceDetails/ServiceCards/4.svg'
					alt='Social Media Marketing'
				/>
			</div>
		</>
	);
};

export default ServiceCards;

const DesignElements = () => {
	return (
		<div className="relative z-[40] w-screen h-full">
			<img
				src='/assets/BlogPage/squiggle.svg'
				alt='White squiggle'
				className='absolute -top-[120px] -left-[100px] -rotate-[23.69deg]'
			/>
			<img
				src='/assets/BlogPage/chat-bubble.svg'
				alt='Chat bubble'
				className='absolute top-[150px] -right-[150px]'
			/>
		</div>
	);
};

export default DesignElements;

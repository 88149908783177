// interface GoogleResponse {
// 	success: boolean;
// 	challenge_ts: string;
// 	hostname: string;
//   }

import { iContactResponse } from "lib/interfaces";

const contactApiUrl = `${process.env.REACT_APP_API_URL}/contact`;

interface IContactService {
  sendMessage: (
    name: string,
    email: string,
    message: string
    // captchaToken:string
  ) => Promise<iContactResponse>;
}

export const contactService: IContactService = {
  async sendMessage(name, email, message) {
    try {
      const response = await fetch(contactApiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name,
          email,
          message,
        }),
      });
      const result: any = await response.json();
      return Promise.resolve(result);
    } catch (error) {
      return Promise.reject(error);
    }
  },
};

import { useGSAP } from "@gsap/react";
import MobileFooter from "components/Footer/Mobile/MobileFooter";
import MobileHeader from "components/Header/MobileHeaders/MobileHeader";
import MobileImagineSection from "components/Home/MobileComponents/ServicesPage/ImagineSection/MobileImagineSection";
import MobileNewHeights from "components/Home/MobileComponents/ServicesPage/NewHeightsSection/MobileNewHeights";
import MobileServiceDetails from "components/Home/MobileComponents/ServicesPage/PinkSections/ServiceDetails/MobileServiceDetails";
import MobileValuesSection from "components/Home/MobileComponents/ServicesPage/PinkSections/ValuesSection/MobileValuesSection";
import FAQ from "components/Services/FAQ/FAQ";
import { gsap } from "gsap";
import ScrollToPlugin from "gsap/dist/ScrollToPlugin";
import ScrollTrigger from "gsap/dist/ScrollTrigger";
import { toTop } from "lib/utils";
import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

const MobileServices: React.FC = () => {
  const location = useLocation();

  useGSAP(() => {
    const sections = document.querySelectorAll<HTMLElement>("[data-bgcolor]");
    if (visibleSection === null) {
      setVisibleSection("imagine");
      setPrevVisibleSection("imagine");
    }
    sections.forEach((section, i) => {
      const prevBgColor =
        i === 0 ? "#ffffff" : sections[i - 1].dataset.bgcolor || "";

      gsap.timeline({
        scrollTrigger: {
          trigger: section,
          scroller: "#scrollDemo",
          start: "top 50%",
          end: "bottom 50%",
          onEnter: () => {
            if (prevVisibleSection !== section.id) {
              setVisibleSection(section.id || null);
              setPrevVisibleSection(section.id);
            }
            gsap.to("#scrollDemo", {
              backgroundColor: section.dataset.bgcolor,
              color: section.dataset.textcolor,
              overwrite: "auto",
            });
            gsap.to(section, {
              opacity: 1,
              visibility: "visible",
              duration: 1.5,
            });
          },
          onLeave: () => {
            if (section.id === "faq") {
            } else {
              gsap.to(section, {
                opacity: 0,
                visibility: "hidden",
                duration: 1.5,
              });
            }
          },
          onEnterBack: () => {
            if (prevVisibleSection !== section.id) {
              setVisibleSection(section.id || null);
              setPrevVisibleSection(section.id);
            }
            gsap.to("#scrollDemo", {
              backgroundColor: section.dataset.bgcolor,
              color: section.dataset.textcolor,
              overwrite: "auto",
            });
            gsap.to(section, {
              opacity: 1,
              visibility: "visible",
              duration: 1.5,
            });
          },
          onLeaveBack: () => {
            gsap.to("#scrollDemo", {
              backgroundColor: prevBgColor,
              overwrite: "auto",
            });
            gsap.to(section, {
              opacity: 0,
              visibility: "hidden",
              duration: 1.5,
            });
          },
        },
      });
    });
  }, []);

  const scrollDemoRef = useRef(null);

  const [lastScroll, setLastScroll] = useState<number>(0);

  const [prevVisibleSection, setPrevVisibleSection] = useState<string | null>(
    null
  );
  const [visibleSection, setVisibleSection] = useState<string | null>(null);

  const handleScroll = () => {
    const head = document.getElementById("header");
    const headLines = document.getElementById("headLines");
    const headLinks = document.getElementById("headLinks");

    headLinks?.classList.add("hidden");
    headLinks?.classList.remove("flex");
    headLines?.classList.remove("hidden");

    if (scrollDemoRef.current) {
      const { scrollTop } = scrollDemoRef.current;

      if (scrollTop > lastScroll) {
        head?.classList.remove("scroll-up");
        head?.classList.add("scroll-down");
      } else {
        head?.classList.remove("scroll-down");
        head?.classList.add("scroll-up");
      }

      setLastScroll(scrollTop);
    }
  };

  useEffect(() => {
    if (location.pathname === "/services/faq") {
      const faqSection = document.getElementById("faq");
      if (faqSection && scrollDemoRef.current) {
        gsap.to(scrollDemoRef.current, {
          scrollTo: { y: faqSection.offsetTop - 100, autoKill: false },
          duration: 1,
        });
      }
    } else {
      toTop()
      // const imagine = document.getElementById("imagine");
      // if (imagine && scrollDemoRef.current) {
      //   gsap.to(scrollDemoRef.current, {
      //     scrollTo: { y: imagine.offsetTop - 100, autoKill: false },
      //     duration: 1,
      //   });
      // }
    }
  }, [location.pathname]);

  return (
    <div
      id="scrollDemo"
      ref={scrollDemoRef}
      onScroll={handleScroll}
      className="relative flex flex-col items-center justify-start w-full h-screen overflow-x-hidden overflow-y-scroll laptop:hidden main"
    >
      <div className=" fixed top-0 z-[301] flex items-center w-full bg-white h-fit py-[10px]  ">
        <MobileHeader />
      </div>
      <section id="imagine" className={`section`} data-bgcolor="#ffffff">
        <div className="relative flex flex-col items-center justify-center overflow-x-visible h-[750px] w-[450px]">
          <MobileImagineSection isVisible={visibleSection === "imagine"} />
        </div>
      </section>
      <section
        id="pinkSections"
        className={`h-[2100px] container relative flex flex-col items-center w-[450px] mx-auto opacity-0`}
        data-bgcolor="#FF0175"
      >
        <MobileValuesSection />
        <MobileServiceDetails />
      </section>

      <section
        id="newHeights"
        className={`h-[930px] section`}
        data-bgcolor="#FFD402"
      >
        <div className="h-[930px] relative flex flex-col items-center justify-center w-[450px] overflow-x-visible">
          <div className="w-[450px] h-[930px] flex flex-col items-center justify-center ">
            <MobileNewHeights isVisible={visibleSection === "newHeights"} />
          </div>
        </div>
      </section>
      <section id="faq" className={`h-fit section`} data-bgcolor="#FF0175">
        <div className="relative flex flex-col items-center justify-center overflow-x-visible h-fit widthClass">
          <div className="w-[450px] h-fit flex flex-col items-center justify-center ">
            <FAQ />
          </div>
        </div>
      </section>
      <div className="relative w-full overflow-x-clip laptop:hidden bg-squeeze-pink">
        <img
          src="/assets/Mobile/Footer/wiggle2.svg"
          alt="Background"
          className="w-[100vw] scale-110 z-30"
        />
        <MobileFooter />
      </div>
      <ToastContainer />
    </div>
  );
};

export default MobileServices;

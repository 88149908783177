import { useEffect, useRef, useState } from "react";
import CarouselElement from "./CarouselElement";
import { gsap } from "gsap";

import { BlogPost } from "lib/interfaces";
import MobileCarouselElement from "./MobileCarouselElement";

const Carousel = ({ posts }: { posts: BlogPost[] }) => {
  const sliderRefs = useRef<HTMLDivElement[]>([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isTweening, setIsTweening] = useState(false);
  const [featuredData, setFeaturedData] = useState<BlogPost[]>([]);

  useEffect(() => {
    const filteredData = posts.filter((post) => post.isFeatured);
    setFeaturedData(filteredData);
  }, [posts]);

  useEffect(() => {
    sliderRefs.current.forEach((slide, index) => {
      gsap.set(slide, {
        xPercent: index > 0 ? 100 : 0,
      });
    });
  }, [featuredData]);

  const gotoSlide = (value: number) => {
    if (isTweening) return;
    setIsTweening(true);

    const nextIndex =
      (currentIndex + value + featuredData.length) % featuredData.length;
    const currentSlide = sliderRefs.current[currentIndex];
    const nextSlide = sliderRefs.current[nextIndex];

    gsap.set(nextSlide, { xPercent: value > 0 ? 100 : -100 });

    gsap.to(currentSlide, {
      xPercent: value > 0 ? -100 : 100,
      onComplete: () => setIsTweening(false),
    });

    gsap.to(nextSlide, { xPercent: 0 });

    setCurrentIndex(nextIndex);
  };


  return (
    <div className=" relative tablet:aspect-[2.5] aspect-[288/335] break:w-[1183px] w-[100vw] tablet:w-[90vw] tablet:rounded-[27px] overflow-x-clip tablet:overflow-clip">
      {featuredData.map((featuredPost, idx) => (
        <div
          key={idx}
          className="absolute w-full max-laptop:h-full "
          ref={(el) => (sliderRefs.current[idx] = el!)}
        >
          <CarouselElement featuredPost={featuredPost} key={featuredPost.id} />
          <MobileCarouselElement
            featuredPost={featuredPost}
            key={featuredPost.id + "Mobile"}
          />
        </div>
      ))}
      <button
        onClick={() => {
          gotoSlide(-1);
        }}
        className="w-[8vw] tablet:hidden aspect-[288/2200] z-40 absolute bottom-0 left-0 flex items-center justify-center"
      >
        <div className="mobile-l:scale-125">
          <img
            src="/assets/BlogPage/arrow-l-mobile.svg"
            alt="Left arrow"
            className=""
          />
        </div>
      </button>
      <button
        onClick={() => {
          gotoSlide(1);
        }}
        className="w-[8vw] tablet:hidden aspect-[288/2200] z-40 absolute bottom-0 right-0 flex items-center justify-center"
      >
        <div className="mobile-l:scale-125">
          <img
            src="/assets/BlogPage/arrow-r-mobile.svg"
            alt="Left arrow"
            className=""
          />
        </div>
      </button>
      <button
        className="absolute left-[2%] top-1/2 -translate-y-1/2 z-40 max-tablet:hidden"
        onClick={() => {
          gotoSlide(-1);
        }}
      >
        <img src="/assets/BlogPage/arrowL.svg" alt="Arrow left" />
      </button>
      <button
        className="absolute right-[2%] top-1/2 -translate-y-1/2 z-40 max-tablet:hidden"
        onClick={() => {
          gotoSlide(1);
        }}
      >
        <img src="/assets/BlogPage/arrowR.svg" alt="Arrow right" />
      </button>
    </div>
  );
};

export default Carousel;

import React from 'react'

const TitleText = () => {
  return (
    <div className="text-squeeze-yellow font-poppins leading-[97%] -tracking-[3%] text-[32.21px]">
    <div className="flex space-x-[3px]">
      <p>the </p>
      <span className="font-poppinsEb text-[36.58px] -rotate-[7.08deg] -translate-y-[30px]">
        odd
      </span>{" "}
      <span className="font-poppinsEb text-[36.58px] -translate-y-[10px]">one</span> <span className="font-poppinsEb text-[36.58px] rotate-[8.47deg] -translate-y-[30px]">out</span>
    </div>
    <p>in the marketing</p>
    <p>world</p>
  </div>
  )
}

export default TitleText
import React from 'react'
import ServicesParallaxElements from './ServicesParallaxElements';
import ServiceCards from './ServiceCards';

const MobileServiceDetails = () => {
    return (
		<div className='h-[1900px] relative flex flex-col items-center justify-center w-[450px] pt-[1000px] overflow-x-visible'>
			<div className='w-[450px] h-[1900px] flex flex-col items-center justify-center '>
				<div className='w-[450px] h-[1900px] realtive flex items-center justify-center' id='serviceDetailsCont'>
					<ServicesParallaxElements />
					<ServiceCards />
				</div>
			</div>
		</div>
	);
}

export default MobileServiceDetails
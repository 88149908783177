import { BlogPost } from "lib/interfaces";
import React from "react";
import tStyles from "./textSizes.module.css";
import { formatDate } from "lib/utils";
import { Link } from "react-router-dom";

const MobileCarouselElement = ({
  featuredPost,
}: {
  featuredPost: BlogPost;
}) => {
  const apiUrl = `${process.env.REACT_APP_API_URL}`;

  return (
    <div className="relative w-full tablet:hidden aspect-[288/335] flex flex-col justify-end rounded-[15px] mobile-l:rounded-[20px]">
      <div className="w-full aspect-[288/140] z-10 absolute top-0 left-0">
        <img
          src={`${apiUrl}${featuredPost.thumbnail}`}
          alt=""
          className="aspect-[288/170] mobile-l:aspect-[288/150] object-cover w-full rounded-[15px] mobile-l:rounded-t-[20px] rounded-b-none"
        />
      </div>
      <div className="w-full aspect-[288/207] z-20 bg-squeeze-pink rounded-[15px] mobile-l:rounded-[20px] px-[4%] mobile-m:px-[7%] text-white space-y-[7px] mobile-l:space-y-[12px] flex flex-col items-start justify-center ">
        <p
          className={`${tStyles.catText} uppercase tracking-[0.11em] text-squeeze-yellow font-robotom max-tablet:px-[3%] max-mobile-m:px-[7%]`}
        >
          {featuredPost.category.title || ""}
        </p>
        <p
          className={`line-clamp-3 ${tStyles.featuredTitle} font-poppinsEb tracking-[-0.015em] leading-[1.12em] max-tablet:px-[3%] max-mobile-m:px-[7%]`}
        >
          {featuredPost.title || ""}
        </p>
        <div
          className={`flex w-full ${tStyles.infoText} max-tablet:px-[3%] max-mobile-m:px-[7%]`}
        >
          <p className="font-extralight font-robotoF">
            {formatDate(featuredPost.createdAt)}
          </p>
        </div>
        <Link
          to={`/blog/${featuredPost.title}/${featuredPost.id}`}
          className={`${tStyles.buttonText} aspect-[90/27] mobile-l:aspect-[90/27] mobile-m:mx-[3%] mx-[7%] uppercase flex items-center rounded-[34.87px] justify-center bg-transparent border-[0.66px] border-squeeze-yellow font-poppinsSb text-squeeze-yellow w-fit `}
        >
          <p className="mobile-l:px-[19px] px-[14px]">Read more</p>
        </Link>
      </div>
    </div>
  );
};

export default MobileCarouselElement;

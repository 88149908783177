import React from "react";

const TitleText = ({ isVisible }: { isVisible: boolean }) => {
  return (
    <div className="text-squeeze-yellow font-poppins leading-[97%] -tracking-[3%] text-[59px]">
      <div className="flex space-x-[10px]">
        <p>the </p>
        <span className="font-poppinsEb text-[] -rotate-[7.08deg] -translate-y-[40px]">
          odd
        </span>{" "}
        <span className="font-poppinsEb text-[] -translate-y-[10px]">one</span> <span className="font-poppinsEb text-[] rotate-[8.47deg] -translate-y-[40px]">out</span>
      </div>
      <p>in the marketing</p>
      <p>world</p>
    </div>
  );
};

export default TitleText;

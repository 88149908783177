import Footer from "../Footer";
import MobileFooter from "../Mobile/MobileFooter";
import MobileNewsletter from "../Mobile/MobileNewsletter";
import NewsletterApplicaiton from "./NewsletterApplicaiton";
import YellowFooter from "./YellowFooter";

const BlogFooters = () => {
  return (
    <>
      
      <MobileNewsletter />
      <div className="max-laptop:hidden z-[48]">
        <NewsletterApplicaiton />
        <YellowFooter />
      </div>
      <Footer />

      <div className="relative w-full overflow-x-clip laptop:hidden bg-squeeze-yellow">
        <img
          src="/assets/Mobile/Footer/wiggle2.svg"
          alt="Background"
          className="w-[100vw] scale-110 z-30"
        />
        <MobileFooter />
      </div>
    </>
  );
};

export default BlogFooters;

import { Heading } from "lib/interfaces";

export const extractHeadings = (htmlContent: string): Heading[] => {
  const div = document.createElement("div");
  div.innerHTML = htmlContent;

  const headingsArray: Heading[] = [];
  let currentLevel = -1; // Initialize current level to -1 (outside any heading)
  let counter = 1; // Counter for headings

  const headingsElements = div.querySelectorAll("h1, h2, h3, h4, h5, h6");

  headingsElements.forEach((el) => {
    const level = parseInt(el.tagName[1]) - 1; // Adjust level to 0-based index
    const text = (el as HTMLElement).innerText;
    const id = text
      .toLowerCase()
      .replace(/\s+/g, "-")
      .replace(/[^\w-]/g, "");

    // Reset counter if heading level changes
    if (level !== currentLevel) {
      counter = 1;
      currentLevel = level;
    }

    const numberedText = `${counter}. ${text}`;
    el.id = `${id}-${counter}`; // Setting an ID with numeration to each heading for the links to target
    headingsArray.push({ level, text: numberedText, id });
    counter++;
  });

  return headingsArray;
};

export const formatDate = (date: Date | string): string => {
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "long",
    day: "2-digit",
  };
  return new Date(date).toLocaleDateString("en-US", options);
};

export const toTop = () => {
  window.scrollTo({ top: 0, behavior: "smooth" });
};

export const copyToClipboard = (text: string) => {
  navigator.clipboard.writeText(text);
};

export const facebookShare = (title: string, link: string) => {
  const postTitle = encodeURIComponent(title);
  const postLink = encodeURIComponent(link);
  const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${postLink}&quote=${postTitle}`;
  window.open(facebookShareUrl, "_blank");
};

export const validateEmail = (email: string) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

import { Category } from "lib/interfaces";
import React, { useEffect, useRef, useState } from "react";

interface Props {
  categories: Category[];
  selectedCategory: string;
  setSelectedCategory: (category: string) => void;
}

const MobileCategories: React.FC<Props> = ({
  categories,
  selectedCategory,
  setSelectedCategory,
}) => {
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const [velocity, setVelocity] = useState(0);
  const [lastX, setLastX] = useState(0);
  const [lastTime, setLastTime] = useState(0);
  const [animationFrame, setAnimationFrame] = useState<number | null>(null);

  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    stopDeceleration();
    setIsDragging(true);
    setStartX(e.pageX - (scrollContainerRef.current?.offsetLeft || 0));
    setScrollLeft(scrollContainerRef.current?.scrollLeft || 0);
    setLastX(e.pageX);
    setLastTime(Date.now());
  };

  const handleTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
    stopDeceleration();
    setIsDragging(true);
    setStartX(
      e.touches[0].pageX - (scrollContainerRef.current?.offsetLeft || 0)
    );
    setScrollLeft(scrollContainerRef.current?.scrollLeft || 0);
    setLastX(e.touches[0].pageX);
    setLastTime(Date.now());
  };

  const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!isDragging || !scrollContainerRef.current) return;

    const currentX = e.pageX;
    const currentTime = Date.now();
    const deltaX = currentX - lastX;
    const deltaTime = currentTime - lastTime;

    if (deltaTime > 0) {
      const currentVelocity = (deltaX / deltaTime) * 16; // Normalize to roughly 60fps
      setVelocity(currentVelocity);
    }

    const x = currentX - (scrollContainerRef.current.offsetLeft || 0);
    const walk = x - startX;
    scrollContainerRef.current.scrollLeft = scrollLeft - walk;

    setLastX(currentX);
    setLastTime(currentTime);
  };

  const handleTouchMove = (e: React.TouchEvent<HTMLDivElement>) => {
    if (!isDragging || !scrollContainerRef.current) return;

    const currentX = e.touches[0].pageX;
    const currentTime = Date.now();
    const deltaX = currentX - lastX;
    const deltaTime = currentTime - lastTime;

    if (deltaTime > 0) {
      const currentVelocity = (deltaX / deltaTime) * 16; // Normalize to roughly 60fps
      setVelocity(currentVelocity);
    }

    const x = currentX - (scrollContainerRef.current.offsetLeft || 0);
    const walk = x - startX;
    scrollContainerRef.current.scrollLeft = scrollLeft - walk;

    setLastX(currentX);
    setLastTime(currentTime);
  };

  const stopDragging = () => {
    if (isDragging) {
      setIsDragging(false);
      startDeceleration();
    }
  };

  const startDeceleration = () => {
    let currentVelocity = velocity;

    const decelerate = () => {
      if (!scrollContainerRef.current) return;

      // Smooth deceleration with easing
      currentVelocity *= 0.95; // Slightly slower deceleration

      const newScrollLeft =
        scrollContainerRef.current.scrollLeft - currentVelocity;
      const maxScroll =
        scrollContainerRef.current.scrollWidth -
        scrollContainerRef.current.offsetWidth;

      // Bounce effect at edges
      if (newScrollLeft <= 0) {
        currentVelocity = 0;
        scrollContainerRef.current.scrollLeft = 0;
      } else if (newScrollLeft >= maxScroll) {
        currentVelocity = 0;
        scrollContainerRef.current.scrollLeft = maxScroll;
      } else {
        scrollContainerRef.current.scrollLeft = newScrollLeft;
      }

      if (Math.abs(currentVelocity) > 0.1) {
        setAnimationFrame(requestAnimationFrame(decelerate));
      } else {
        stopDeceleration();
      }
    };

    setAnimationFrame(requestAnimationFrame(decelerate));
  };

  const stopDeceleration = () => {
    if (animationFrame) {
      cancelAnimationFrame(animationFrame);
      setAnimationFrame(null);
    }
    setVelocity(0);
  };

  useEffect(() => {
    document.addEventListener("mouseup", stopDragging);
    document.addEventListener("touchend", stopDragging);

    return () => {
      document.removeEventListener("mouseup", stopDragging);
      document.removeEventListener("touchend", stopDragging);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDragging]); // Added isDragging to dependencies

  return (
    <div className="w-full overflow-hidden laptop:hidden">
      <div
        ref={scrollContainerRef}
        className={`flex items-center gap-3 mobile-l:gap-2 pb-4 overflow-x-auto tracking-[11%] scrollbar-hide font-semibold whitespace-nowrap laptop:hidden font-roboto ${
          isDragging ? "cursor-grabbing" : "cursor-grab"
        }`}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        style={{
          WebkitOverflowScrolling: "auto",
          msOverflowStyle: "none",
          scrollbarWidth: "none",
        }}
      >
        {categories.map((cat) => (
          <button
            key={cat.id}
            onClick={() => {
              setSelectedCategory(
                selectedCategory === cat.title ? "" : cat.title
              );
            }}
            className={`flex  ${
              selectedCategory === cat.title
                ? "text-white whitespace-nowrap bg-squeeze-pink border-transparent"
                : "bg-transparent border-squeeze-darkBlue text-squeeze-darkBlue"
            }  tracking-[0.11em] mobile-l:tracking-[0.12em] rounded-[93.94px] text-[11px] mobile-l:text-[16px] border-[0.65px] uppercase h-full mobile-l:px-[20px] mobile-l:py-[6.5px] px-[10px] py-[5px] transition-colors duration-200`}
          >
            {cat.title}
          </button>
        ))}
      </div>
    </div>
  );
};

export default MobileCategories;

import Carousel from "components/Blog/CarouselSection/Carousel";
import Categories from "components/Blog/Categories/Categories";
import PostGrid from "components/Blog/Posts/PostGrid";
import BlogFooters from "components/Footer/Blog/BlogFooters";
import BlogHeaders from "components/Header/BlogHeaders";
import { useCategory } from "contexts/CategoryContext";
import { toastDefaultOptions } from "lib/consts";
import { BlogPost, iData } from "lib/interfaces";
import { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import { blogService } from "services/blog";
import { isSafari } from "react-device-detect";

const Blog = () => {
  const [lastScroll, setLastScroll] = useState<number>(0);
  const [posts, setPosts] = useState<BlogPost[]>([]); // Original list of posts
  const [filteredPosts, setFilteredPosts] = useState<BlogPost[]>([]); // Filtered posts

  useEffect(() => {
    const fetchData = () =>
      blogService
        .getPosts()
        .then((response) => {
          if (response?.success) {
            setPosts((response.data as iData).items);
            setFilteredPosts((response.data as iData).items); 
           // Initialize filteredPosts with all posts
          } else {
            toast.error(response?.message, toastDefaultOptions);
          }
        })
        .catch((Error) => {
          toast.error(Error, toastDefaultOptions);
        });
    fetchData();
  }, []);

  const { selectedCategory } = useCategory();

  useEffect(() => {
    if (selectedCategory !== "") {
      const filtered: BlogPost[] = posts.filter(
        (post) =>
          post.category.title.toLowerCase() === selectedCategory.toLowerCase()
      );
      setFilteredPosts(filtered); // Update filteredPosts instead of overwriting posts
    } else {
      setFilteredPosts(posts); // Reset to all posts if no category is selected
    }
  }, [selectedCategory, posts]); // Depend on `posts` and `selectedCategory`

  const handleScroll = () => {
    const head = document.getElementById("header");
    const scrollTop = window.scrollY;
    const headLines = document.getElementById("headLines");
    const headLinks = document.getElementById("headLinks");

    headLinks?.classList.add("hidden");
    headLinks?.classList.remove("flex");
    headLines?.classList.remove("hidden");

    if (scrollTop <= 0) {
      head?.classList.remove("scroll-down");
      head?.classList.add("scroll-up");
    } else if (scrollTop > lastScroll) {
      head?.classList.remove("scroll-up");
      head?.classList.add("scroll-down");
    } else {
      head?.classList.remove("scroll-down");
      head?.classList.add("scroll-up");
    }
    setLastScroll(scrollTop);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastScroll]);

  return (
    <div className="relative flex flex-col items-center justify-center w-full h-fit overflow-x-clip">
      <BlogHeaders />
      <div className="break:w-[1183px] max-break:px-[6vw] w-full laptop:h-full h-fit tablet:mt-[150px] mt-[5vh] space-y-[5vh]">
        <div
          className={`relative flex items-center justify-center w-full h-fit ${
            isSafari ? "laptop:mb-[7vh] mb-[1vh]" : "mb-[1vh]"
          } max-tablet:mt-[10vh]`}
        >
          <Carousel posts={posts} />
        </div>
        <Categories />
        <PostGrid posts={filteredPosts} />
      </div>
      <BlogFooters />
      <ToastContainer />
    </div>
  );
};

export default Blog;

import { FAQ } from "lib/interfaces";

const FAQItem = ({
  item,
  idx,
  isOpen,
  setOpenIndex,
}: {
  item: FAQ;
  idx: number;
  isOpen: boolean;
  setOpenIndex: React.Dispatch<React.SetStateAction<number | null>>;
}) => {
  const handleClick = () => {
    setOpenIndex((prevIndex) => (prevIndex === idx ? null : idx));
  };

  return (
    <div
      className={`w-[300px] laptop:w-[441px] border-[0.98px] laptop:border-[1.5px] border-white text-white cursor-pointer flex justify-between items-center px-[20px] laptop:px-[36px]
        overflow-hidden
        transition-all duration-300 ease-in-out ${
          isOpen
            ? "py-[16px] laptop:py-[30px] max-h-[500px]"
            : "max-h-[66px] laptop:max-h-[106px]"
        }
        ${
          isOpen
            ? "rounded-[10px] laptop:rounded-[20px] space-x-[15px]"
            : "rounded-[84.82px] laptop:rounded-[130px] space-x-[5px]"
        }
        `}
      onClick={handleClick}
    >
      <div className={`flex items-center relative justify-between font-roboto`}>
        <div>
          <p
            className={`whitespace-nowrap text-[15px] laptop:text-[22.5px] leading-[114.5%] tracking-[-2%] absolute w-[223px] laptop:w-[340px] top-1/2 transform -translate-y-1/2 transition-opacity duration-300 ${
              isOpen ? "opacity-0" : "opacity-100"
            }`}
             dangerouslySetInnerHTML={{ __html: item.q.replace(/\n/g, '<br />') }}
          />
          

          <p
            className={`whitespace-nowrap font-roboto text-[15px] laptop:text-[20px] leading-[114.5%] tracking-[-3%] transition-opacity duration-300 ${
              isOpen ? "opacity-100" : "opacity-0"
            }`}
            dangerouslySetInnerHTML={{ __html: item.a.replace(/\n/g, '<br />') }}
          />
        </div>
      </div>

      <img
        src={`/assets/FAQ/arrowM.svg`}
        alt="arrow mobile"
        className={`transition-transform duration-300 ${
          isOpen ? "rotate-180" : "rotate-0"
        } laptop:hidden`}
      />
      <img
        src={`/assets/FAQ/arrow.svg`}
        alt="arrow "
        className={`transition-transform duration-300 ${
          isOpen ? "rotate-180" : "rotate-0"
        } max-laptop:hidden`}
      />
    </div>
  );
};
export default FAQItem;

import { useCategory } from "contexts/CategoryContext";
import { toastDefaultOptions } from "lib/consts";
import { Category, iData } from "lib/interfaces";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { categoryService } from "services/blog";
import DesktopCategories from "./DesktopCategories";
import MobileCategories from "./MobileCategories";

const Categories = () => {
  const [categories, setCategories] = useState<Category[]>([]);

  useEffect(() => {
    const fetchData = () =>
      categoryService
        .getCategories()
        .then((response) => {
          if (response?.success) {
            const categoryList: iData = response.data as iData
            getUniqueCategories(categoryList.items);
          } else toast.error(response?.message, toastDefaultOptions);
        })
        .catch((Error) => {
          toast.error(Error, toastDefaultOptions);
        });
    fetchData();
  }, []);

  const getUniqueCategories = (categories: Category[]) => {
    const uniqueCategories = Array.from(
      new Map(categories.map((cat) => [cat.id, cat])).values()
    ); // Remove duplicates by `id`
  
    // Sort to ensure "Other" is always last
    uniqueCategories.sort((a, b) => {
      if (a.title.toLowerCase() === "other") return 1; // Move "Other" to the end
      if (b.title.toLowerCase() === "other") return -1;
      return 0; // Keep other categories in their existing order
    });
  
    setCategories(uniqueCategories);
  };

  const { selectedCategory, setSelectedCategory } = useCategory();

  return (
    <>
     <DesktopCategories
	 categories={categories}
        selectedCategory={selectedCategory}
        setSelectedCategory={setSelectedCategory}/>
        <MobileCategories
	 categories={categories}
        selectedCategory={selectedCategory}
        setSelectedCategory={setSelectedCategory}/>
    
    </>
  );
};

export default Categories;

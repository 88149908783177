import { Heading } from "lib/interfaces";
import { extractHeadings } from "lib/utils";
import { useState, useEffect } from "react";

const ContentsTab: React.FC<{ content: string }> = ({ content }) => {
    const [headings, setHeadings] = useState<Heading[]>([]);
  
    useEffect(() => {
       
        const headingsArray = extractHeadings(content);
        setHeadings(headingsArray); 
      }, [content]);
    
      return (
        <div className="text-squeeze-darkBlue space-y-[5%] laptop:bg-[#F8F8F8] laptop:p-4">
          <p className="font-robotoB leading-[129%] text-[12px] laptop-l:text-[14px] uppercase">Contents</p>
          <div className="font-roboto text-[11px] laptop:text-[13px] ">
          {headings.map((heading) => (
      <div
        key={heading.id}
        className={`level-${heading.level} flex w-[90%]`} // Using flexbox for layout
        style={{ paddingLeft: `${heading.level - 1}rem` }} // Adjusting indentation
      >
        <div className="mr-[2px]"> 
          <p className="">{heading.text.split(". ")[0]}.</p> {/* Extract number */}
        </div>
        <div>
          <p className="">{heading.text.split(". ")[1]}</p> {/* Extract remaining text */}
        </div>
      </div>
    ))}
          </div>
        </div>
      );
    };
  
  export default ContentsTab;
import { useState } from "react";
import Post from "./Post";

import { BlogPost } from "lib/interfaces";

const PostGrid = ({ posts }: { posts: BlogPost[] }) => {
  const [visibleCount, setVisibleCount] = useState(6);
  const totalPosts = posts.length; // State for filtered posts

  const showMorePosts = () => {
    setVisibleCount((prevCount) => Math.min(prevCount + 6, posts.length));
  };
  return (
    <div className="flex flex-col items-center w-full ">
      {totalPosts <= 0 ? (
        <div className="flex items-center justify-center w-full h-full">
          No posts available from this category.
        </div>
      ) : (
        <div className="grid max-tablet:space-y-[2vh] w-full grid-cols-1 tablet:gap-6 laptop:gap-12 tablet:grid-cols-2 h-fit laptop:grid-cols-3 auto-rows-fr">
          {posts.slice(0, visibleCount).map((item) => (
            <Post post={item} key={item.id} />
          ))}
        </div>
      )}

      <button
        className={`${
          visibleCount < totalPosts ? "" : "opacity-0 disabled"
        } hover:bg-squeeze-pink hover:text-white break:px-[20px] px-[15px] py-[7px] break:py-[12px] h-fit w-fit uppercase border-squeeze-pink border-[0.73px] rounded-[38.67px] my-[20px] laptop:my-[50px] break:text-[16pt] text-sm  leading-[117.5%] font-poppinsSb text-squeeze-pink`}
        onClick={showMorePosts}
      >
        Show More
      </button>
    </div>
  );
};

export default PostGrid;

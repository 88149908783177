import HeadingSection from "components/Blog/SingleBlog/HeadingSection";
import MainSection from "components/Blog/SingleBlog/MainSection";
import BlogFooters from "components/Footer/Blog/BlogFooters";
import BlogHeaders from "components/Header/BlogHeaders";
import { toastDefaultOptions } from "lib/consts";
import { BlogPost } from "lib/interfaces";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { blogService } from "services/blog";

const SingleBlog = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { title, id } = useParams<{ title: string; id: string }>();
  const [post, setPost] = useState<BlogPost | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  
  useEffect(() => {
    const fetchPost = () => {
      setIsLoading(true);
      blogService
        .getPost(id ? id : "")
        .then((response) => {
          if (response?.success) {
            setPost(response.data as BlogPost);
          } else toast.error(response?.message, toastDefaultOptions);
        })
        .catch((Error) => {
          toast.error(Error, toastDefaultOptions);
        })
        .finally(() => {
          setIsLoading(false);
        });
    };
    fetchPost();
  }, [id]);

  return (
    <div
      className="relative flex flex-col items-center justify-center h-full overflow-visible overflow-x-clip"
      id="scrollDemo"
    >
      <BlogHeaders />
      <div className="max-w-[1254px] max-[1254px]:px-[3vw] w-full h-full mt-[15vh] laptop:my-[25vh]">
        {post ? (
          <>
            <HeadingSection post={post} />
            <MainSection post={post} />
          </>
        ) : isLoading ? (
          <div className="flex items-center justify-center w-full h-screen">
            Loading...
          </div>
        ) : (
          <div className="flex items-center justify-center w-full h-screen">
            Post not found.
          </div>
        )}
      </div>
      <BlogFooters />
      <ToastContainer />
    </div>
  );
};

export default SingleBlog;

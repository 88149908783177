import HeadingTextAnimation from "components/HeadingTextAnimation";
import Arrows from "./Arrows";
import AccordionItems from "./AccordionItems";

const GetThere = ({ isVisible }: { isVisible: boolean }) => {
	return (
		<div className='h-[1080px] relative flex flex-col items-center justify-center widthClass overflow-x-clip'>
			<div className='w-[1512px] h-[1080px] flex flex-col items-center justify-center '>
				<div className='w-[490px] text-squeeze-darkBlue absolute top-[297px] left-[293px] font-poppinsEb text-[67px] leading-[106%]'>
					<HeadingTextAnimation
						idx={"1"}
						isVisible={isVisible}
						text='How are we'
					/>

					<HeadingTextAnimation
						idx={"2"}
						isVisible={isVisible}
						text='going to get'
					/>

					<HeadingTextAnimation idx={"3"} isVisible={isVisible} text='you' />
				</div>
				<div className='absolute top-[456px] left-[434px] font-poppinsBli text-[79px] leading-[106%] text-squeeze-pink lowercase -rotate-[4deg]'>
					<HeadingTextAnimation
						idx={"3"}
						isVisible={isVisible}
						text='There'
						extraClass='min-w-[110%]'
					/>
				</div>
				<div className='absolute top-[450px] left-[655px] font-poppinsBli text-[79px] leading-[106%] text-squeeze-darkBlue lowercase -rotate-[4deg]'>
					<HeadingTextAnimation
						idx={"3"}
						isVisible={isVisible}
						text='?'
						extraClass='min-w-[110%]'
					/>
				</div>
				<Arrows />
				<img
					src='/assets/GetThereSection/uLine.svg'
					alt='underline'
					className={`absolute top-[525px] left-[440px] `}
				/>
				<AccordionItems />
			</div>
		</div>
	);
};

export default GetThere;

import { iResponse } from "lib/interfaces";

const blogApiUrl = `${process.env.REACT_APP_API_URL}/blog`;
const categoryApiUrl = `${process.env.REACT_APP_API_URL}/category`;
const bannerApiUrl = `${process.env.REACT_APP_API_URL}/banner`;
const newsletterApiUrl = `${process.env.REACT_APP_API_URL}/newsletter/subscribe`;

interface IBlogService {
  getPosts: () => Promise<iResponse>;
  getPost: (id: string) => Promise<iResponse>;
  getBanner: (id: number) => Promise<iResponse>;
  subscribeToNewsletter: (
    email: string,
    bannerId: number
  ) => Promise<iResponse>;
}

interface ICategoryService {
  getCategories: () => Promise<iResponse>;
}

export const blogService: IBlogService = {
  async getPost(id: string) {
    try {
      const response = await fetch(`${blogApiUrl}/${id}`, {
        method: "GET",
      });
      const result: iResponse = await response.json();

      return Promise.resolve(result);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getPosts() {
    try {
      const response = await fetch(blogApiUrl, {
        method: "GET",
      });
      const result: iResponse = await response.json();

      return Promise.resolve(result);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getBanner(id: number) {
    try {
      const response = await fetch(`${bannerApiUrl}/${id}`, {
        method: "GET",
      });
      const result: iResponse = await response.json();
      return Promise.resolve(result);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async subscribeToNewsletter(
    email: string,
    bannerId: number
  ): Promise<iResponse> {
    try {
      const response = await fetch(newsletterApiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email,
          bannerId,
        }),
      });

      if (!response.ok) {
        throw new Error(`Failed to subscribe: ${response.statusText}`);
      }

      const result: iResponse = await response.json();
      return result;
    } catch (error) {
      console.error("Error subscribing to newsletter:", error);
      throw error;
    }
  },
};

export const categoryService: ICategoryService = {
  async getCategories() {
    try {
      const response = await fetch(categoryApiUrl, {
        method: "GET",
      });
      const result: iResponse = await response.json();

      return Promise.resolve(result);
    } catch (error) {
      return Promise.reject(error);
    }
  },
};

import { toastDefaultOptions } from "lib/consts";
import { validateEmail } from "lib/utils";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { blogService } from "services/blog";

const MobileNewsletter = () => {
  const [email, setEmail] = useState<string>("");
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };
  const handleSubmit = () => {
    if (email === "") {
      toast.error("Please enter an e-mail.", toastDefaultOptions);
    } else if (!validateEmail(email)) {
      toast.error("Please enter a valid mail address.", toastDefaultOptions);
      return;
    } else {
      blogService
        .subscribeToNewsletter(email, 4)
        .then((response) => {
          if (response?.success) {
            toast.success(
              "You have successfuly subscribed to our newsletter. Check your E-mail for confirmation.",
              toastDefaultOptions
            );
          } else toast.error(response?.error?.message, toastDefaultOptions);
        })
        .catch((Error) => {
          toast.error(Error, toastDefaultOptions);
        });
    }
  };
  return (
    <div className="flex items-center w-full jsutify-center laptop:hidden">
      <div className="relative flex items-center justify-center w-full bg-squeeze-yellow">
        <img
          src="/assets/Mobile/Footer/yellow-wiggle.svg"
          alt="Background"
          className="w-[1022px] min-w-[120vw] absolute -top-[25%] tablet:-top-[40%] "
        />
        <div className="z-20 space-y-[10px] flex flex-col items-start justify-center h-fit py-[5vh] relative">
          <div className="relative font-poppins font-semibold mobile-l:text-[] text-[22px] leading-[129%] text-squeeze-darkBlue mb-[4px] space-y-[5px] whitespace-nowrap">
            Get the{" "}
            <span className="font-poppinsBl text-squeeze-pink">
              freshest
            </span>{" "}
            content
            <div className="border-b-[0.73px] border-squeeze-darkBlue w-full"></div>
            <img
              src="/assets/Mobile/Footer/white-squiggle.svg"
              alt="White squiggle"
              className="absolute -left-[110px] z-30 -top-[140px]"
            />
          </div>
          <div className="w-full text-[13px] text-squeeze-darkBlue font-robotol justify-items-start whitespace-nowrap ">
            <p>Subscribe to our blog and get notified about every</p>
            <p>blog post we ever write 'till the end of time.</p>
          </div>
          <div className="items-center gap-2 grid grid-cols-3 w-full max-w-[270px] leading-[129%] relative">
            <img
              src="/assets/Mobile/Footer/chat-bubble.svg"
              alt="Chat bubble"
              className="absolute right-0 -top-[15px] z-0"
            />
            <input
              type="text"
              value={email}
              placeholder="Email address"
              className="font-poppinsL z-[10] col-span-2 focus:outline-none text-[10px] placeholder:text-squeeze-darkBlue text-squeeze-darkBlue border-[0.75px] border-squeeze-darkBlue rounded-[92px] w-full  bg-transparent px-[20px] py-2"
              onChange={handleChange}
            />
            <button
              onClick={handleSubmit}
              className="w-full z-[10] h-fit hover:bg-squeeze-darkBlue cursor-pointer bg-squeeze-pink text-white rounded-[92px] flex items-center justify-center font-poppinsSb text-[10px] py-2"
            >
              SUBSCRIBE
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileNewsletter;

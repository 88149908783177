import { Link } from "react-router-dom";
import tStyles from "./textSizes.module.css";
import { BlogPost } from "lib/interfaces";
import { formatDate } from "lib/utils";

const CarouselElement = ({ featuredPost }: { featuredPost: BlogPost }) => {
  const apiUrl = `${process.env.REACT_APP_API_URL}`;
  return (
    <div className="relative flex items-start justify-start tablet:aspect-[2.5] tablet:w-full max-tablet:aspect-[288/242] max-tablet:hidden">
      <div className="tablet:aspect-[1.487] mobile-m:px-[7%] text-white space-y-[7px] mobile-l:space-y-[12px] laptop:w-[60%] laptop-l:px-[94px] tablet:w-[60%] z-20 bg-squeeze-pink flex flex-col items-start justify-center tablet:justify-center max-tablet:pt-6 max-tablet:h-full laptop:space-y-[18px] max-tablet:rounded-[14.88px] rounded-[27px] aspect-[288/242] ">
        <p
          className={`${tStyles.catText} uppercase tracking-[0.11em] text-squeeze-yellow font-robotom max-tablet:px-[3%] max-mobile-m:px-[7%]`}
        >
          {featuredPost.category.title || ""}
        </p>
        <p
          className={`laptop:text-[50pt] max-mobile-m:line-clamp-3 ${
            tStyles.featuredTitle
          } font-poppinsEb tracking-[-0.015em] leading-[1.12em] max-tablet:px-[3%] max-mobile-m:px-[7%]`}
        >
          {featuredPost.title || ""}
        </p>
        <div
          className={`flex w-full ${tStyles.infoText} max-tablet:px-[3%] max-mobile-m:px-[7%]`}
        >
          <p className="font-robotol">{formatDate(featuredPost.createdAt)}</p>
        </div>
        <Link
          to={`/blog/${featuredPost.title}/${featuredPost.id}`}
          className={`${tStyles.buttonText} aspect-[90/27] max-tablet:mx-[3%] max-mobile-m:mx-[7%] uppercase flex items-center max-tablet:rounded-[34.87px] justify-center rounded-full bg-transparent border-[0.66px] tablet:border-[0.93px] hover:bg-squeeze-yellow hover:text-squeeze-darkBlue border-squeeze-yellow w-[146px] tablet:h-[39px] font-poppinsSb text-squeeze-yellow max-tablet:w-fit `}
        >
          Read more
        </Link>
      </div>
      <div className="aspect-[547.03/503.37] absolute max-tablet:hidden right-0 z-10 tablet:h-full tablet:w-fit bg-opacity-50 rounded-[27px] max-tablet:aspect-[288/242]">
        {featuredPost.thumbnail ? (
          <img
            src={`${apiUrl}${featuredPost.thumbnail}`}
            alt="filler"
            className="object-cover aspect-[547.03/503.37] max-tablet:aspect-[288/242] w-full rounded-[27px]"
          />
        ) : (
          <img
            src="/assets/temp/bullseye.png"
            alt="filler"
            className="object-cover aspect-[547.03/503.37] max-tablet:aspect-[288/242] w-full rounded-[27px]"
          />
        )}
      </div>
    </div>
  );
};

export default CarouselElement;



import TextElements from "./TextElements";

const AboutSection = ({ isVisible }: { isVisible: boolean }) => {
  return (
    <div className="flex items-center justify-center w-full h-full space-x-[33px]">
      <TextElements isVisible={isVisible} />
      <img src="/assets/AboutPage/founders.svg" alt="The founders" className="w-[550px]"/>
    </div>
  );
};

export default AboutSection;

import { useState } from "react";
import OpenMobileHeader from "./OpenMobileHeader";
import { Link } from "react-router-dom";

const MobileHeader = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <div className="z-[301] mx-[5vw] relative w-full h-full min-h-[60px] flex items-center justify-between">
      <Link to="/" className=" z-[301]">
        {isOpen ? (
          <img
            src="/assets/mobile-logo-white.svg"
            alt="Squeeze creative agency logo white"
            className=""
          />
        ) : (
          <img
            src="/assets/mobile-logo.svg"
            alt="Squeeze creative agency logo "
            className=""
          />
        )}
      </Link>
     <div
        onClick={() => {
          setIsOpen((prev) => !prev);
        }}
        className="z-[301] h-full flex items-center justify-end"
      >
        <img
          src="/assets/Mobile/Header/x.svg"
          alt="Three yellow lines"
          className={`absolute transition-all duration-300 ease-in-out ${
            isOpen ? "opacity-100 scale-100" : "opacity-0 scale-95"
          }`}
        />
        <img
          src="/assets/Mobile/Header/pink-lines.svg"
          alt="Three pink lines"
          className={`absolute transition-all duration-300 ease-in-out ${
            isOpen ? "opacity-0 scale-95" : "opacity-100 scale-100"
          }`}
        />
      </div>
      {isOpen && <OpenMobileHeader setIsOpen={setIsOpen} isOpen={isOpen} />}
    </div>
  );
};

export default MobileHeader;

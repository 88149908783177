"use client";

import { useState } from "react";

export default function AccordionItem({
  title,
  children,
  number,
  eClass,
}: {
  title: string;
  children: React.ReactNode;
  number: string;
  eClass?: string;
}) {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <div
      className={`w-[450px] min-h-[68px] border-[1.5px] ${
        isOpen
          ? "border-none bg-squeeze-pink rounded-[16.57px] animate-[rounded] transition-ease-in-out duration-300"
          : "border-squeeze-darkBlue rounded-[71px] animate-[rounded] transition-ease-in-out duration-300"
      }  relative`}
    >
      <div
        className={`flex flex-col transition ease-in-out `}
        onMouseEnter={() => {
          setIsOpen(true);
        }}
        onMouseLeave={() => {
          setIsOpen(false);
        }}
      >
        <div className={` `}>
          <div
            className={`relative w-full max-h-[68px] transition-[height] duration-300 ${
              isOpen ? "h-[38px]" : "h-[68px]"
            }`}
          >
            <p
              className={`absolute top-[13px] left-[20px] font-poppins text-[18.47px] ${
                isOpen ? "text-white" : "text-squeeze-pink"
              }`}
            >
              {number}
            </p>
            <p
              className={`${
                isOpen ? "opacity-0" : "opacity-100"
              }  absolute bottom-[8px] left-[52px] text-[22px] font-poppins text-squeeze-darkBlue`}
            >
              {title}
            </p>
          </div>
          <div className="max-laptop:col-span-2 lg:col-span-2 "></div>
        </div>

        <div
          className={`overflow-hidden h-fit transition-max-h duration-300 ${
            isOpen ? `max-h-[120px]` : "max-h-0"
          } w-full`}
        >
          <div
            className={`w-[450px] relative h-full flex items-end justify-end`}
          >
            <div className="whitespace-nowrap h-full w-full pl-[52px] pb-[20px] text-[22.2px] font-roboto text-white leading-[110.5%] -tracking-[2%]">
              {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import { unusedFeatures } from "lib/consts";
import { BlogPost } from "lib/interfaces";
import { copyToClipboard, formatDate } from "lib/utils";
import { useState } from "react";
import { FacebookShareButton, LinkedinShareButton } from "react-share";

const HeadingSection = ({ post }: { post: BlogPost }) => {
  const currentURL = window.location.href;
  const [shareOpen, setShareOpen] = useState<boolean>(false);
  const [urlCopied, setUrlCopied] = useState<boolean>(false);
  const [shareHovered, setShareHovered] = useState<boolean>(false);

  const apiUrl = `${process.env.REACT_APP_API_URL}`;
  return (
    <div className="flex max-laptop:flex-col items-center w-full h-fit gap-[4vh] laptop:gap-[4%] ">
      <div className="w-[80vw] laptop:w-[50.5%] h-fit aspect-[703/423] ">
        <img
          src={
            post.thumbnail && post.thumbnail !== ""
              ? `${apiUrl}${post.thumbnail}`
              : "/assets/temp/bullseye.png"
          }
          alt="Blog cover"
          className="object-cover w-full h-full rounded-[27px]"
        />
      </div>
      <div className="w-[80vw] laptop:w-[40%] grid grid-rows-3 ">
        <div className="flex flex-col justify-end row-span-2 max-laptop:space-y-[2vh]">
          <p className="uppercase font-robotoB text-sm laptop:text-[14px] leading-[129%] tracking-[0.11rem] text-squeeze-pink">
            {post.category.title}
          </p>
          <p className="laptop:line-clamp-4 font-poppinsEb text-3xl laptop:text-[48px] leading-[109%] -tracking-[0.015em] text-squeeze-darkBlue">
            {post.title}
          </p>
        </div>
        <div className="row-span-1 laptop:space-y-[18px] max-laptop:space-y-[2vh]">
          <div className="flex items-center h-fit text-[13px] laptop:text-[17px] leading-[129%] text-squeeze-darkBlue mt-[5%]">
            {unusedFeatures && (
              <>
                <p className="font-roboto">by {post.author.name}</p>
                <p className="text-base text-squeeze-darkBlue mx-[2.5%]">|</p>
              </>
            )}
            <p className="font-robotol">{formatDate(post.createdAt)}</p>
          </div>
          <div className="flex text-squeeze-pink font-poppinsSb gap-[5.5%] text-sm laptop-l:text-base">
            <button
              onClick={() => {
                setShareOpen(true);
              }}
              onMouseEnter={() => {
                setShareHovered(true);
              }}
              onMouseLeave={() => {
                setShareHovered(false);
              }}
              className={`items-center justify-center flex w-[50%] laptop:w-[100px] laptop:aspect-[110/48] laptop:hover:text-white laptop:hover:bg-squeeze-pink max-laptop:bg-white border-[0.93px] leading-[117.5%] max-laptop:py-2 rounded-[49px] border-squeeze-pink`}
            >
              {shareOpen ? (
                <div className="flex items-center justify-around max-laptop:px-[20%] w-full">
                  <FacebookShareButton url={currentURL}>
                    <img
                      src={`/assets/BlogPage/fb-${
                        shareHovered ? "white" : "pink"
                      }.svg`}
                      alt="fb"
                      className="h-fit max-laptop:hidden"
                    />
                    <img
                      src={`/assets/BlogPage/fb-pink.svg`}
                      alt="fb"
                      className="h-fit laptop:hidden"
                    />
                  </FacebookShareButton>
                  <LinkedinShareButton url={currentURL} >
                    <img
                      src={`/assets/BlogPage/linkedin-${
                        shareHovered ? "white" : "pink"
                      }.svg`}
                      alt="linkedIn"
                      className="h-fit max-laptop:hidden"
                    />
                    <img
                      src={`/assets/BlogPage/linkedin-pink.svg`}
                      alt="linkedIn"
                      className="h-fit laptop:hidden"
                    />
                  </LinkedinShareButton>
                </div>
              ) : (
                <p>SHARE</p>
              )}
            </button>
            <button
              onClick={() => {
                copyToClipboard(currentURL);
                setUrlCopied(true);
              }}
              className={`${shareOpen ? "" : "hidden"} ${
                urlCopied ? "text-xs laptop-l:text-sm" : ""
              } items-center justify-center flex w-[50%] laptop:w-[29%] hover:text-white hover:bg-squeeze-pink border-[0.93px] leading-[117.5%] py-2 laptop:py-3 rounded-[49px] border-squeeze-pink`}
            >
              {urlCopied ? "LINK COPIED" : "COPY LINK"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeadingSection;
